import { axiosInstance } from './axios';
import store from '@/store';

/**
 * Get all IA conversations  
 * @return {Promise<Array>}
 * @throws {Error}
 */
const getAllIaConversation = async () => {
  try {
    const response = await axiosInstance.get("/conversation/all-conversation");
    store.dispatch("handleAddConversation", response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

/**
 * Send IA message
 * @param {string} conversationId
 * @param {string} message
 * @param {string} fileName
 * @return {Promise<Object>}
 * @throws {Error}
 */
const sendIaConversation = async (conversationId, message, fileName) => {
  try {
    const formData = new FormData();

    // Get IA response to classify message
    const iaResponse = await axiosInstance.post(`/conversation/classifier/${conversationId}/`, { phrase: message });
    const iaClass = iaResponse.data[0];
    const previousIaClass = await axiosInstance.get(`/conversation/previous-class/${conversationId}/`);

    // Save message
    formData.append("message_applicant", message);
    formData.append("conversation", conversationId);
    formData.append("detected_class", iaResponse.data);
    formData.append("fileName", fileName);

    const response = await axiosInstance.post("/conversation/messages/", formData);
    store.dispatch("handleConversationChange", response.data);

    // Get IA response
    if (iaClass === "KO") {
      // Check previous detected class

      if (previousIaClass.data === "best_jobs") {
        return await axiosInstance.post(`/conversation/best-jobs/${conversationId}/`, { phrase: message, isKO: true });
      } else {
        return { eventstream: 'eventstream', iaClass: iaClass };
      }
    } else if (iaClass === "best_jobs") {
      return await axiosInstance.post(`/conversation/best-jobs/${conversationId}/`, { phrase: message });
    } else {
      if (iaClass.length === 0) {
        return false;
      }
      return { eventstream: 'eventstream', iaClass: iaClass };
    }
  } catch (error) {
    console.error(error);
    return { eventstream: null };
  }
};

/**
 * Create a new conversation
 * @throws {Error}
 */
const createConversation = async () => {
  try {
    const response = await axiosInstance.post("/conversation/new-conversation/");
    response.data.date_modification = new Date().toISOString();
    store.dispatch("handleAddConversation", response.data);
  } catch (error) {
    console.error(error);
  }
};

/**
 * Change conversation title
 * @param {string} conversationId
 * @param {string} title
 * @throws {Error}
 */
const changeConversationTitle = async (conversationId, title) => {
  try {
    await axiosInstance.put(`/conversation/new-conversation/${conversationId}/`, { titre: title });
    store.dispatch("handleTitleConversationChange", conversationId);
  } catch (error) {
    console.error(error);
  }
};

/**
 * Delete a conversation
 * @param {Object} conversation
 * @throws {Error}
 */
const deleteConversation = async (conversation) => {
  try {
    await axiosInstance.delete(`/conversation/delete-conversation/${conversation.conversation_id}/`);
    store.dispatch("handleDeleteConversation", conversation);
  } catch (error) {
    console.error(error);
  }
};

/**
 * Like a message
 * @param {string} messageId
 * @throws {Error}
 */
const likeMessage = async (messageId) => {
  try {
    await axiosInstance.post(`/conversation/toggle-like/${messageId}/`);
  } catch (error) {
    console.error(error);
  }
};

/**
 * Dislike a message
 * @param {string} messageId
 * @param {string} message
 * @throws {Error}
 */
const dislikeMessage = async (messageId, message) => {
  try {
    await axiosInstance.post(`/conversation/toggle-dislike/${messageId}/`, { dislike_raison: message });
  } catch (error) {
    console.error(error);
  }
};

/**
 * Signal a message
 * @param {string} messageId
 * @param {string} feedback
 * @throws {Error}
 */
const signalMessage = async (messageId, feedback) => {
  try {
    await axiosInstance.post(`/conversation/signaler-message/${messageId}/`, { signal_raison: feedback });
  } catch (error) {
    console.error(error);
  }
};

export {
  getAllIaConversation,
  sendIaConversation,
  createConversation,
  changeConversationTitle,
  deleteConversation,
  likeMessage,
  dislikeMessage,
  signalMessage
};
