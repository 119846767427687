import { getUser } from '../../services/account.service';
export default {
  state: {
    user: {
      role: "",
      conversation: [],
      alerte: [],
      favorie_job: [],
    },
  },
  mutations: {
    updateUser(state, { type, payload }) {
      if (type) {
        state.user[type] = [...state.user[type], payload];
      } else {
        state.user = payload;
      }
    },    
    updateUserRole(state, role) {
      state.user.role = role;
    },
    resetUser(state) {
      state.user = { role: "" };
    },
    deleteUserPropreties(state, { type, payload }) {
      state.user[type] = state.user[type].filter((list) => list.id !== payload);
    },
    deleteUser(state) {
      console.log("Réinitialisation de l'utilisateur");
      state.user = {};
    },

    // alert
    addAlert(state, payload) {
      state.user.alerte = [...state.user.alerte, payload];
    },
    updateAlert(state, payload) {
      if (!Array.isArray(state.user.alerte)) {
        state.user.alerte = [];
      }

      // Créer une copie de state.user.alerte
      const alerteCopy = [...state.user.alerte];

      // Trouver l'index de l'alerte à mettre à jour
      const alertIndex = alerteCopy.findIndex(
        (alerte) => alerte.id === payload.id
      );

      if (alertIndex !== -1) {
        // Remplacer l'alerte existante par la nouvelle dans la copie
        alerteCopy[alertIndex] = payload;
        alerteCopy.pop();
      } else {
        // Ajouter une nouvelle alerte à la copie
        alerteCopy.push(payload);
      }

      // Mettre à jour state.user.alerte avec la copie modifiée
      state.user.alerte = alerteCopy;
    },
    // favorite
    addFavoris(state, payload) {
      state.user.favorie_job = [
        ...state.user.favorie_job,
        { job_offer: payload },
      ];
    },
    deleteFavoris(state, payload) {
      state.user.favorie_job = state.user.favorie_job.filter(
        (job) => job.job_offer.id !== payload
      );
    },
    addPost(state, post) {
      state.posts.push(post);
    },
    removePost(state, postId) {
      state.posts = state.posts.filter(post => post.id !== postId);
    },
    addCommentInStore(state, { postId, newComment }) {
      const post = state.posts.find(post => post.id === postId);
      console.log('Post trouvé:', post); // Vérifier si le post existe
      if (post) {
        if (!post.commentList) {
          post.commentList = []; // S'assurer que commentList existe
        }
        post.commentList.push(newComment);
        post.comments += 1;
        console.log('Nouveaux commentaires:', post.commentList); // Vérifier le résultat
      }
    }
  },
  actions: {
    async fetchUser({ commit }) {
      try {
        const userData = await getUser();
        if (!userData || !userData.type_user) {
          commit('setLoggedIn', false); 
          return;
        }
    
        commit('updateUser', { type: null, payload: userData });
        commit('setLoggedIn', true); 
      } catch (error) {
        commit('setLoggedIn', false); 
      }
    },
    
    /**
     * handle user change in store
     * @param {String} type - type of user data : alert, candidature, conversation, favoris
     * - set to null for whole user object
     * @param {Object} payload - user data
     */
    handleUserChange({ commit }, { type, payload }) {
      commit("updateUser", { type, payload });
    },
    handleUserRoleChange({ commit }, role) {
      commit("updateUserRole", role);
    },
    handleDeleteUserPropreties({ commit }, { type, payload }) {
      commit("deleteUserPropreties", { type, payload });
    },
    logout({ commit }) {
      console.log("Action logout appelée");
      // Vérifie si le token d'accès est absent
      if (!localStorage.getItem("accessToken")) {
        console.log("Aucun token d'accès, réinitialisation de l'utilisateur");
        commit("deleteUser"); // Réinitialise l'état de l'utilisateur
        return; // Sort de l'action
      }
    },
    // alert
    /**
     * Add a new alert to the user's alert list
     * @param {Object} payload - The alert to add
     * @returns {void}
     * */
    handleAddAlert({ commit }, payload) {
      commit("addAlert", payload);
    },

    // favorite
    /**
     * Add a job offer to the user's favorite list
     * @param {Object} payload - The job offer to add
     * @returns {void}
     */
    handleAddFavoris({ commit }, payload) {
      commit("addFavoris", payload);
    },
    /**
     * Remove a job offer from the user's favorite list
     * @param {Object} payload - The job ID offer to remove
     * @returns {void}
     * */
    handleDeleteFavoris({ commit }, payload) {
      commit("deleteFavoris", payload);
    },

    handleChangeAlert({ commit }, payload) {
      commit("updateAlert", payload);
    },
  },
  getters: {
    getUser: (state) => {
      // sort conversation by date modification
      if (state.user.conversation) {
        state.user.conversation.sort((a, b) => {
          return new Date(b.date_modification) - new Date(a.date_modification);
        });
      }
      console.log(state.user)
      return state.user || {};
    },
    userRole: (state) => {
      return state.user?.type_user || ""; 
    },
  },
};
