import axios from "axios";
import store from "../store";
import { logout } from "./account.service";

export const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
export const apiBaseUrl = `${baseUrl}/api`;

export let accessToken = null;
export function setAccessToken(value) {
  accessToken = value;
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((config) => {
  const csrfToken = getCookie("csrftoken");
  if (csrfToken) {
    config.headers["X-CSRFToken"] = csrfToken;
  }
  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  if (
    config.data instanceof FormData &&
    config.headers["Content-Type"] === "application/json"
  ) {
    config.headers["Content-Type"] = "multipart/form-data";
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const authenticationStatusCodes = new Set([401, 403]);
    const loginUrl = "/user/token/";

    const { config, response: { status } } = error;

    if (
      !authenticationStatusCodes.has(status) ||
      config.url === loginUrl ||
      store.getters.isLoggedIn === false
    ) {
      return Promise.reject(error);
    }

    // Déconnecte l'utilisateur si une erreur d'authentification est détectée
    await logout();
    return Promise.reject(error);
  }
);

/**
 
@deprecated use axiosInstance directly */
export default function axiosRequest(_contentType = "application/json") {
  return axiosInstance;
}
