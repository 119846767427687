// store/index.js
import { createStore } from "vuex";
import UserModule from "./user/user.module";
import { setAccessToken } from "../services/axios";
import { deleteConversation } from "../services/conversation.service";
import subscriptionModule from "./subscription/subscription.module";

export default createStore({
  modules: {
    userModule: UserModule,
    subscription : subscriptionModule
  },
  state: {
    isLoggedIn: JSON.parse(localStorage.getItem('isLoggedIn')) || false,
    token: localStorage.getItem('token') || null, 
  },
  mutations: {
    // isLoggedIn
    setLoggedIn(state, value) {
      state.isLoggedIn = value;
      localStorage.setItem('isLoggedIn', JSON.stringify(value)); 
    },
    setToken(state, token) {
      console.log("Setting token:", token); // Log le token avant de le stocker
      state.token = token; 
      localStorage.setItem('token', token);
    },
    resetUser(state) {
      console.log("Resetting user and removing token"); // Log pour réinitialisation
      state.userModule.user = {};
      localStorage.removeItem('token');
    },
    // alert
    updateAlert(state, payload) {
      state.userModule.user.alerte = [...state.userModule.user.alerte, payload];
    },
    deleteAlert(state, payload) {
      state.userModule.user.alerte = state.userModule.user.alerte.filter(alerte => alerte.id !== payload.id);
    },
    // candidature
    updateCandidature(state, payload) {
      state.userModule.user.postulation = [...state.userModule.user.postulation, payload];
    },
    // conversation
    addConversation(state, payload) {
      state.userModule.user.conversation = [...state.userModule.user.conversation, payload];
      // initialize messages array
      state.userModule.user.conversation[state.userModule.user.conversation.length - 1].messages = [];
    },
    updateConversation(state, payload) {      
      const conversationIndex = state.userModule.user.conversation.findIndex(conversation => conversation.conversation_id === payload.conversation);
      if (conversationIndex !== -1) {
        state.userModule.user.conversation[conversationIndex].messages = [
          ...state.userModule.user.conversation[conversationIndex].messages,
          payload
        ];
      }
    },
    updateLlmConversation(state, payload) {
      const conversationIndex = state.userModule.user.conversation.findIndex(conversation => conversation.conversation_id === payload.conversation_id);
      if (conversationIndex !== -1) {
        delete payload.messages[payload.messages.length - 1].message_applicant;
        state.userModule.user.conversation[conversationIndex].messages = [
          ...state.userModule.user.conversation[conversationIndex].messages,
          payload.messages[payload.messages.length - 1]
        ];
      }
    },
    updateTitleConversation(state, payload) {
      const conversationIndex = state.userModule.user.conversation.findIndex(conversation => conversation.conversation_id === payload.conversation_id);
      if (conversationIndex !== -1) {
        state.userModule.user.conversation[conversationIndex].titre = payload.titre;
      }
    },
    deleteConversation(state, payload) {
      const conversationIndex = state.userModule.user.conversation.findIndex(conversation => conversation.conversation_id === payload.conversation_id);
      if (conversationIndex !== -1) {
        state.userModule.user.conversation.splice(conversationIndex, 1);
      }
    },
  },
  actions: {
    setToken({ commit }, token) {
      console.log("Action setToken called with token:", token); // Log dans l’action pour vérifier l’appel
      commit('setToken', token);
    },
    // logout
    logout({ commit }) {
      console.log("Logging out and clearing token");
      setAccessToken(null);
      commit("setLoggedIn", false);
      commit("resetUser");
    },
    handleLoggedIn({ commit }, value) {
      commit("setLoggedIn", value);
      localStorage.setItem('isLoggedIn', JSON.stringify(value));
    },
    // alert
    handleAlertChange({ commit }, payload) {
      commit("updateAlert", payload);
    },
    handleDeleteAlert({ commit }, payload) {
      commit("deleteAlert", payload);
    },
    // candidature
    handleCandidatureChange({ commit }, payload) {
      commit("updateCandidature", payload);
    },
    // conversation
    handleAddConversation({ commit }, payload) {
      commit("addConversation", payload);
    },
    handleConversationChange({ commit }, payload) {
      commit("updateConversation", payload);
    },
    handleLlmConversationChange({ commit }, payload) {
      commit("updateLlmConversation", payload);
    },
    handleTitleConversationChange({ commit }, payload) {
      commit("updateTitleConversation", payload);
    },
    handleDeleteConversation({ commit }, payload) {
      commit("deleteConversation", payload);
    },
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    token: (state) => {
      console.log("Getter for token called, token:", state.token); // Log dans le getter pour voir le token
      return state.token;
    },
  },
});
