import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import { toaster } from '@/utils/toast/toast.js';
import{ addUserToStore } from"../../src/services/account.service"

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/candidate/home/Home.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/search",
    name: "search",
    component: () => import("@/views/candidate/search/Search.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/joboffer/:id",
    name: "joboffer",
    component: () => import("@/views/candidate/joboffer/Joboffer.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/messaging",
    name: "messaging",
    component: () => import("@/views/all/messaging/Messaging.vue"),
    meta: { requiresAuth: true, role: 'applicant' }
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/candidate/register/Register.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/candidate/login/Login.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/social-media",
    name: "social",
    component: () => import("@/views/all/social-media/Reseaux.vue"),
    meta: { requiresAuth: false } 
    // aucune authentification requise
    // la fonction pour ajouter un post/commentaire est protégée par v-if="isLoggedIn"
  },
  {
    path: "/prices",
    name: "prices",
    component: () => import("@/views/candidate/prices/Prices.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import("@/views/all/blog/Blog.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/blog-section/:sectiontype",
    name: "blog-section",
    component: () => import("@/views/all/blog-section/BlogSection.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/blog/:sectiontype/article/:articleid",
    name: "article",
    component: () => import("@/views/all/article/Article.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/views/candidate/contact/Contact.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("@/views/candidate/faq/Faq.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/favorite",
    name: "favorite",
    component: () => import("@/views/candidate/favorite/Favorite.vue"),
    meta: { requiresAuth: true, role: 'applicant' }
  },
  {
    path: "/alerts",
    name: "Alerts",
    component: () => import("@/views/candidate/alerts/Alerts.vue"),
    meta: { requiresAuth: true, role: 'applicant' }
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/candidate/dashboard/Dashboard.vue"),
    meta: { requiresAuth: true, role: 'applicant' }
  },
  {
    path: "/subscription/:id",
    name: "subscription",
    component: () => import("@/views/candidate/subscriptions/Subscriptions.vue"),
    meta: { requiresAuth: false, }
  },
  {
    path: "/cgu",
    name: "cgu",
    component: () => import("@/views/all/cgu/CGU.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/politique-confidentialite",
    name: "politique-confidentialite",
    component: () => import("@/views/all/politique-confidentialite/PolitiqueConfidentialite.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/cgv",
    name: "cgv",
    component: () => import("@/views/all/cgv/CGV.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/mentions-legales",
    name: "mentions-legales",
    component: () => import("@/views/all/mentions-legales/MentionsLegales.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/profil",
    name: "profil",
    component: () => import("@/views/candidate/profil/Profil.vue"),
    meta: { requiresAuth: true, role: 'applicant' }
  },
  {
    path: "/profil-preview",
    name: "profil-preview",
    component: () => import("@/views/candidate/profile-preview/ProfilePreview.vue"),
    meta: { requiresAuth: false } // Changer en "true" lorsque la page sera terminée
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/candidate/settings/Settings.vue"),
    meta: { requiresAuth: true, role: 'applicant' }
  },
  {
    path: "/recruiter/profil",
    name: "profil-recruiter", // nom profil déjà existant attention!!
    component: () => import("@/views/recruiter/profil/Profil.vue"),
    meta: { requiresAuth: true, role: 'recruiter' } 
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/all/about/About.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/recruiter/prices",
    name: "recruiter-prices",
    component: () => import("@/views/recruiter/prices/Prices.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/recruiter/subscription/:id",
    name: "recruiter-subscription",
    component: () => import("@/views/recruiter/subscriptions/Subscriptions.vue"),
    meta: { requiresAuth: false, }
  },
  {
    path: "/achat",
    name: "PurchaseRecap",
    component: () => import("@/views/all/purchase-funnel/TunnelAchat.vue"),
    meta: { requiresAuth: true }// Changer en "true" lorsque la page sera terminée
  },
  {
    path: "/stripe-success",
    name: "StripeSuccess",
    component: () => import("@/views/all/stripe/StripeSuccess.vue"),
    meta: { requiresAuth: false } // Changer en "true" lorsque la page sera terminée
  },
  {
    path: "/stripe-cancel",
    name: "StripeCancel",
    component: () => import("@/views/all/stripe/StripeCancel.vue"),
    meta: { requiresAuth: false } // Changer en "true" lorsque la page sera terminée
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import("@/views/all/password-reset/PasswordResetRequest.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/password-reset-confirm",
    name: "password-reset-confirm",
    component: () => import("@/views/all/password-reset/PasswordResetChange.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/all/404/404.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/recruiter/alerts",
    name: "recruiter-alerts",
    component: () => import("@/views/recruiter/alerts/Alerts.vue"),
    meta: { requiresAuth: true, role: 'recruiter' }
  },
  {
    path: "/recruiter/dashboard",
    name: "recruiter-dashboard",
    component: () => import("@/views/recruiter/dashboard/Dashboard.vue"),
    meta: { requiresAuth: true, role: 'recruiter' }
  },
  {
    path: "/recruiter/register",
    name: "recruiter-register",
    component: () => import("@/views/recruiter/register/Register.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/recruiter/profil-preview",
    name: "recruiter-profil-preview",
    component: () => import("@/views/recruiter/profilpreview/RecruiterProfilPreview.vue"),
    meta: { requiresAuth: false } // Changer en "true" lorsque la page sera terminée
  },
  {
    path: "/recruiter/candidate/:id",
    name: "recruiter-candidate-:id",
    component: () => import("@/views/recruiter/candidate-page/CandidatePage.vue"),
    meta: { requiresAuth: true, role: 'recruiter' } // Changer en "true" lorsque la page sera terminée
  },
  {
    path: "/recruiter/offer",
    name: "recruiter-offer",
    component: () => import("@/views/recruiter/all-offer/Offer.vue"),
    meta: { requiresAuth: true, role: 'recruiter' }
  },
  {
    path: "/recruiter/search",
    name: "recruiter-search",
    component: () => import("@/views/recruiter/search/Search.vue"),
    meta: { requiresAuth: true, role: 'recruiter' }
  },
  {
    path: "/recruiter/login",
    name: "recruiter-login",
    component: () => import("@/views/recruiter/login/Login.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/recruiter/favorite",
    name: "recruiter-favorite",
    component: () => import("@/views/recruiter/favorite/Favorite.vue"),
    meta: { requiresAuth: true, role: 'recruiter' } // Changer en "true" lorsque la page sera terminée
  },
  {
    path: "/recruiter/offer-applications/:id",
    name: "recruiter-offer-applications-id",
    component: () => import("@/views/recruiter/offer-applications/OfferApplications.vue"),
    meta: { requiresAuth: true, role: 'recruiter' }
  },
  {
    path: "/recruiter/offer/new",
    name: "recruiter-offer-new",
    component: () => import("@/views/recruiter/new-offer/NewOffer.vue"),
    meta: { requiresAuth: true, role: 'recruiter' } // Changer en "true" lorsque la page sera terminée
  },
  {
    path: "/recruiter/messaging",
    name: "recruiter-messaging",
    component: () => import("@/views/recruiter/messaging/Messaging.vue"),
    meta: { requiresAuth: true, role: 'recruiter' } // Changer en "true" lorsque la page sera terminée
  },
  {
    path: "/call",
    name: "call",
    component: () => import("@/views/candidate/messaging/Messaging.vue"),
    meta: { requiresAuth: true, role: 'applicant' } // Changer en "true" lorsque la page sera terminée
  },
  {
    path: "/recruiter/offer-applications/:offerId/candidature/:candidateId",
    name: "recruiter-candidature",
    component: () => import("@/views/recruiter/candidature/Candidature.vue"),
    meta: { requiresAuth: false } // Changer en "true" lorsque la page sera terminée
  },
  {
    path: '/recruiter/offer-preview/:id',
    name: 'OfferPreview',
    component: () => import('@/views/recruiter/offer-preview/OfferPreview.vue'),
  }
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  base: "/applicant",
});

router.beforeEach(async (to, _, next) => {
  try {

    await addUserToStore(); 

    const isLoggedIn = store.getters.isLoggedIn;
    const userRole = store.getters.userRole;

    if (to.meta.requiresAuth && !isLoggedIn) {
      next({ name: 'login' });
    } else if (to.meta.type_user && to.meta.type_user !== userRole) {
      next({ name: 'home' });
      await new Promise(resolve => setTimeout(resolve, 1000)); 
      toaster.showErrorPopup("Accès refusé : vous n'avez pas les autorisations nécessaires.", {
        timeout: 5000,
      });
    } else {
      next();
    }
  } catch (error) {
    console.error('Erreur lors de la vérification de l\'authentification:', error);
    next({ name: 'error' }); 
  }
});





export default router;
